<template>
  <div class="baling-home">
    <!-- 首页 -->
    <HomePage />
  </div>
</template>

<script>
import HomePage from '@/views/home-page'
export default {
  name:'Home',
  components:{
    HomePage
  }
}
</script>

<style lang="scss" scoped>
</style>